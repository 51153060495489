/* Accordion */

.accordion {
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
  }
  
  
  .accordion:after {
    content: '\002B';
    font-weight: bold;
    float: right;
    margin-left: 5px;
  }
  
  .accordion.active:after {
    content: "\2212";
  }
  
  .panel {
    padding: 0 18px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
  
  .active + .panel {
    max-height: 1000px; /* o un valor que sea suficientemente grande para contener el contenido real */
  }