label {
    font-weight: 700;
  }

  .field {
    position: relative;
  }
  
  form input[type=text],
  form input[type=tel],
  form input[type=email]{
    width: 100%;
    border-radius: 10px;
    height: 42px;
    background-color: #F5F5F5;
    padding: 16px;
  }

  form textarea {
    width: 100%;
    border-radius: 10px;
    resize: none;
    background-color: #F5F5F5;
    padding: 16px;
  }
  
  form button {
    width: 100%;
    margin: 0%;
  }

  .error {
    color: #db2777;
    font-size: 14px;
    font-weight: 600;
    display: none;
  }
  
  .field input:invalid:focus ~ .error,
  .field textarea:invalid:focus ~ .error {
    display: inline-block;
  }
  .label-before,
  .field input:focus + label::before,
  .field input:valid + label::before,
  .field textarea:focus + label::before,
  .field textarea:valid + label::before {
    line-height: 20px;
    font-size: 12px;
    top: 2px;
    padding: 0x 16px;
    left: 16px;
    color: #191a1b;
    font-weight: 600;
  }
  
  .field label::before {
    content: attr(title);
    position: absolute;
    left: 16px;
    line-height: 50px;
    font-size: 16px;
    color: #666666;
    transition: 300ms all;
  }
  .field input:valid + label::before {
    content: attr(data-title);
  }
  
  .field textarea:valid + label::before {
    content: attr(data-title);
  }
  
  .field input:focus:invalid + label::before,
  .field textarea:focus:invalid + label::before {
    color: #db2777;
  }

  @media only screen and (min-width:768px){
    form textarea {
      height: 120px;
    }
  }